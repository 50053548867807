import { Controller } from 'stimulus'
import Rails from '@rails/ujs'
import autosize from 'autosize'

export default class SubmittableTextareaController extends Controller {
  static targets = ['textarea', 'form']
  static values = { 'min': Number }

  declare formTarget: HTMLFormElement
  declare textareaTarget: HTMLTextAreaElement

  connect() {
    this.formTarget.addEventListener('ajax:success', () => {
      autosize.update(this.textareaTarget);
    })

    setTimeout(() => {
      autosize.update(document.querySelector('textarea'));
    }, 10)
  }

  handleKeypress(e: KeyboardEvent){
    if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {
      var val = this.textareaTarget.value;
      if (typeof this.textareaTarget.selectionStart == "number" && typeof this.textareaTarget.selectionEnd == "number") {
          var start = this.textareaTarget.selectionStart;
          this.textareaTarget.value = val.slice(0, start) + "\n" + val.slice(this.textareaTarget.selectionEnd);
          this.textareaTarget.selectionStart = this.textareaTarget.selectionEnd = start + 1;
          autosize.update(this.textareaTarget);
      }
      return true;
    }
    else if (e.keyCode === 13) {
      Rails.fire(this.formTarget, 'submit')
      e.preventDefault();
      return false
    }

    return true;
  }

  handleChange() {
    autosize.update(this.textareaTarget);
  }
}
import { Controller } from "stimulus"
import ResizeObserver from 'resize-observer-polyfill';

export default class EmbedController extends Controller {
  static targets = ["embedResizer"]
  declare embedResizerTargets: Array<HTMLElement>

  connect() {
    const ro = new ResizeObserver((entries, observer) => {
        for (const entry of entries) {
          const {width, height} = entry.contentRect;
          parent.postMessage({ type: 'page.resized', height: height, width: width }, '*')
        }
    });
    this.embedResizerTargets.forEach((resizer) => {
      ro.observe(resizer);
    })
  }
}

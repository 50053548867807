import { Controller } from 'stimulus';
import HelloSign from 'hellosign-embedded';

export default class EsignatureController extends Controller {
  static targets = ["signingContainer", "signatureIdInput", "form"]
  static values = { clientId: String, signingUrl: String, sandbox: Boolean }

  declare signingContainerTarget: HTMLElement
  declare signatureIdInputTarget: HTMLInputElement
  declare formTarget: HTMLFormElement
  declare clientIdValue: string
  declare signingUrlValue: string
  declare esignClient: HelloSign
  declare sandboxValue: boolean

  connect() {
    this.esignClient = new HelloSign({
      clientId: this.clientIdValue,
      allowCancel: false,
      debug: this.sandboxValue,
      testMode: this.sandboxValue,
    })

    this.esignClient.on('message', this.handleSignature());
    this.esignClient.open(this.signingUrlValue, { container: this.signingContainerTarget })
  }

  handleSignature() {
    var controller = this

    return (data) => {
      if (data.type === HelloSign.messages.USER_SIGN_REQUEST) {
        controller.signatureIdInputTarget.value = data.payload.signatureId
        controller.signingContainerTarget.remove();
        controller.esignClient.close()
        controller.formTarget.submit()
      }
    }
  }
}
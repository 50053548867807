import { Controller } from 'stimulus';
import  _ from 'underscore'

export default class MinCheckedController extends Controller {
  static targets = ['cbx']
  static values = { 'min': Number }

  declare cbxTargets: Array<HTMLInputElement>
  declare cbxTarget: HTMLInputElement
  declare minValue: number

  connect() {
    // Check all if less than min
    if(this.cbxTargets.length <= this.minValue) {
      _.each(this.cbxTargets, function(el) {
        el.checked = true
      })
    }
  }

  check(e: Event) {
    var target = e.currentTarget as HTMLInputElement

    if(!target.checked && this.numChecked() < this.minValue) {
      // hack to prevent the state from changing
      target.checked = !target.checked
      e.preventDefault()
    }
  }

  numChecked():Number {
    var num = 0;
    this.cbxTargets.forEach(function(t) {
      if(t.checked) num++;
    })

    return num;
  }
}
import { Controller } from "stimulus"
import autosize from "autosize";
import { Application } from "stimulus"

export default class FileUploadController extends Controller {
  static targets = ["fileInput", "previewTemplate", "previewFilename", "fileSize", "previewContainer", "inputWrapper"]

  declare fileInputTarget: HTMLInputElement
  declare inputWrapperTarget: HTMLInputElement
  declare previewTemplateTarget: HTMLElement
  declare previewContainerTarget: HTMLElement
  declare fileSizeTarget: HTMLElement
  declare previewFilenameTarget: HTMLElement
  declare files: FileList
  declare reader: FileReader
  declare inputName: string
  declare form: HTMLFormElement

  connect() {
    this.reader = new FileReader
    this.form = this.fileInputTarget.closest('form')

    // Remove the name as a way of preventing submission of an empty
    // field which would erase the image.
    this.inputName = this.fileInputTarget.getAttribute('name')
    this.fileInputTarget.removeAttribute('name')
    this.preview()
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  previewFile(file) {
    this.reader.readAsDataURL(file)
    this.reader.onload = this.onreadHandler()
    var previewContent = this.previewTemplateTarget.innerHTML.replace('{filename}', this.sanitize(file.name)).replace('{filesize}', this.formatBytes(file.size))
    this.appendElement(this.previewContainerTarget, previewContent)
  }

  preview() {
    this.files = this.fileInputTarget.files
    if(this.files.length !== 0) {
      this.previewContainerTarget.innerHTML = ''
      Array.from(this.files).forEach((file) => {
        this.previewFile(file)
      })
      this.hideUploadAfterPreview()
    }
  }

  onreadHandler() {
    const ctrl = this;

    return (e: ProgressEvent<FileReader>) => {
      // ctrl.previewTarget.src = <string> ctrl.reader.result
      // Add the name back in so that upon submission, the file
      // is uploaded
      ctrl.fileInputTarget.setAttribute('name', ctrl.inputName)
    }
  }

  sanitize(string) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    const reg = /[&<>"'/]/ig;
    return string.replace(reg, (match)=>(map[match]));
  }

  appendElement(appendTo, html) {
    const element = document.createElement('div')
    element.innerHTML = html

    appendTo.appendChild(element)
  }

  hideUploadAfterPreview() {
    if(this.inputWrapperTarget.getAttribute('data-file-upload-hide-on-preview')) {
      this.inputWrapperTarget.classList.add('hidden')
      this.fileInputTarget.id = ''
    }
  }
}

import { Controller } from "stimulus"


export default class ContentLoaderController extends Controller {
  static targets = ["destination"]
  static actions = ["load"]
  static values = { source: String }

  declare destinationTarget: HTMLElement
  declare sourceValue: string
  declare status: string

  connect() {
    console.log('connect loader')
    this.status = 'init'
  }

  load() {
    console.log('loading')
    if(this.status !== 'init') { return }

    console.log('fetching')
    fetch(this.sourceValue)
      .then(response => response.text())
      .then(html => {
        this.destinationTarget.innerHTML = html
        this.status = 'loaded'
      })
  }
}

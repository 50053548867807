import { Controller } from 'stimulus';

export default class ImagePreviewInputController extends Controller {
  static targets = ["input", "preview"]

  declare inputTarget: HTMLInputElement
  declare previewTarget: HTMLImageElement
  declare file: File
  declare reader: FileReader
  declare inputName: string

  connect() {
    this.reader = new FileReader

    // Remove the name as a way of preventing submission of an empty
    // field whish would erase the image.
    this.inputName = this.inputTarget.getAttribute('name')
    this.inputTarget.removeAttribute('name')
  }

  preview() {
    this.previewTarget.src = ''

    this.file = this.inputTarget.files[0]
    this.reader.readAsDataURL(this.file)
    this.reader.onload = this.onreadHandler()
  }

  onreadHandler() {
    const previewTarg = this.previewTarget
    const fileReader = this.reader
    const inputTarg = this.inputTarget
    const inputNme = this.inputName

    return (e: ProgressEvent<FileReader>) => {
      previewTarg.src = fileReader.result as string
      // Add the name back in so that upon submission, the file
      // is uploaded
      inputTarg.setAttribute('name', inputNme)
    }
  }
}
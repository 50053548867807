import { Controller } from 'stimulus';

export default class PayabliPaymentFormController extends Controller {
  static targets = [ "submitButton", "tokenInput", "form", "entryPoint", "customer" ]
  static values = { "publicKey": String, "email": String }

  static entryPoint = ''
  static payabliComponent = null

  connect() {
    this.entryPoint = this.entryPointTarget.getAttribute('data-entry-point');
    let config = this.hostedFieldsConfiguration();
    this.payabliComponent = new PayabliComponent(config);
    this.submitButtonTarget.setAttribute('disabled', 'disabled')
  }

  successCallback() {
    var controller = this

    return (result) => {
      controller.tokenInputTarget.value = result.responseData.referenceId;
      controller.formTarget.submit();
    }
  }

  errorCallback() {
    var controller = this

    return (result) => {
      console.log(result)
    }
  }

  tokenizeAndSubmit(event) {
    event.preventDefault();
    this.payabliComponent.payabliExec('method');
  }

  readyCallback() {
    var controller = this;
    return function(data) {
      if(data[1] === true) {
        controller.submitButtonTarget.removeAttribute('disabled');
      }
      else {
        controller.submitButtonTarget.setAttribute('disabled', 'disabled')
      }
    }
  }

  hostedFieldsConfiguration() {
    var config = {
      type: "methodEmbedded",
      rootContainer: "payabli_method_form",
      token: this.publicKeyValue,
      defaultOpen: 'card',
      entryPoint: this.entryPoint,
      card: {
        enabled: true,
        amex: false,
        discover: true,
        visa: true,
        mastercard: true,
        inputs: {   //here we are customizing the input fields
          cardHolderName: {
            label: "Name",
            placeholder: "",
            floating: false,
            value: this.payerNameValue,
            size: 12,
            row: 0,
            order: 0
          },
          cardNumber: {
            label: "Card number",
            placeholder: "1234 1234 1234 1234",
            floating: false,
            size: 6,
            row: 1,
            order: 0
          },
          cardExpirationDate: {
            label: "Exp. Date",
            placeholder: "MM/YY",
            floating: false,
            size: 6,
            row: 1,
            order: 1
          },
          cardCvv: {
            label: "CVV",
            placeholder: "CVV/CVC",
            floating: false,
            size: 6,
            row: 2,
            order: 0
          },
          cardZipcode: {
            label: "Zip code",
            placeholder: "ZIP/POSTAL CODE",
            floating: false,
            size: 6,
            row: 2,
            order: 1
          }
        }
      },
      ach: {
        enabled: false,
        checking: true,
        savings: true
      },
      temporaryToken: false,
      customerData: this.customerData(),
      functionCallBackReady: this.readyCallback(),
      functionCallBackSuccess: this.successCallback(),
      functionCallBackError: this.errorCallback(),
    }

    return config;
  }

  customerData() {
    return {
      customerNumber: this.customerTarget.getAttribute('data-customer-id'),
      firstName: this.customerTarget.getAttribute('data-customer-first-name'),
      lastName: this.customerTarget.getAttribute('data-customer-last-name'),
      billingEmail: this.customerTarget.getAttribute('data-customer-email')
    }
  }
}
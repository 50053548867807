import { Controller } from "stimulus"
import autosize from "autosize";

export default class EngagementMessagesController extends Controller {
  static targets = ["scrollablePanel", "attachmentModal"]

  declare scrollablePanelTarget: HTMLElement
  declare attachmentModalTarget: HTMLElement

  connect() {
    this.autosizeTextareas();
    this.scrollToEnd();
  }

  autosizeTextareas() {
    setTimeout(() => {
      autosize.update(document.querySelector('textarea'));
    }, 10)
  }

  scrollToEnd() {
    setTimeout(() => {
      this.scrollablePanelTarget.scrollTop = this.scrollablePanelTarget.scrollHeight
    }, 10)
  }
}

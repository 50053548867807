import { Controller } from "stimulus";
import autosize from "autosize";

export default class TabsController extends Controller {
  static targets = ["tab", "panel"];
  static classes = ["activeTab"];
  static values = { index: Number };

  declare panelTargets: Array<Element>;
  declare tabTargets: Array<HTMLElement>;
  declare activeTabClasses: Array<string>;
  declare indexValue: number;

  connect() {
    this.showTab();
  }

  change(event: Event) {
    event.preventDefault();
    this.indexValue = this.tabTargets.indexOf(
      event.currentTarget as HTMLElement
    );
  }

  showTab() {
    this.tabTargets.forEach((tab, index) => {
      const panel = this.panelTargets[index];
      panel.classList.toggle("hidden", index != this.indexValue);

      if (index === this.indexValue) {
        tab.setAttribute("aria-selected", "true");
        panel.setAttribute("aria-hidden", "false");
        tab.classList.add(...this.activeTabClasses);
      } else {
        tab.setAttribute("aria-selected", "false");
        panel.setAttribute("aria-hidden", "true");
        tab.classList.remove(...this.activeTabClasses);
      }
    });

    setTimeout(() => {
      autosize.update(document.querySelector("textarea"));
    }, 1);
  }

  indexValueChanged() {
    this.showTab();
  }
}

import { Controller } from 'stimulus';

export default class LawpayPaymentFormController extends Controller {
  static targets = [ "cardNumberInput", "cvvInput", "submitButton", "expDateInput",
                     "ccPostalCodeInput", "tokenInput", "form", "routingNumberInput",
                     "accountNumberInput", "ccTab", "achTab", "achAcctHolderTypeInput",
                     "achAcctTypeInput", "achNameInput", "paymentTypeInput", "ccNameInput",
                     "ccAddr1Input", "ccAddr2Input", "ccLocalityInput", "ccRegionInput" ]
  static values = { "publicKey": String, "email": String, "acceptsCc": Boolean, "acceptsAch": Boolean }

  connect() {
    if(this.acceptsCcValue) {
      var ccConfig = this.hostedFieldsConfiguration(this.ccFieldsConfig());
    }

    if(this.acceptsAchValue) {
      var achConfig = this.hostedFieldsConfiguration(this.achFieldsConfig())
    }

    this.createAlternateDivs();
    if(this.acceptsCcValue) {
      this.ccHostedFields = window.AffiniPay.HostedFields.initializeFields(ccConfig, this.tokenizationCallback())
    }

    if(this.acceptsAchValue) {
      this.achHostedFields = window.AffiniPay.HostedFields.initializeFields(achConfig, this.tokenizationCallback())
    }
    this.submitButtonTarget.setAttribute('disabled', 'disabled')
  }

  createAlternateDivs() {
    if(this.acceptsCcValue) {
      this.replaceInputWithDiv(this.cardNumberInputTarget)
      this.replaceInputWithDiv(this.cvvInputTarget)
    }

    if(this.acceptsAchValue) {
      this.replaceInputWithDiv(this.routingNumberInputTarget)
      this.replaceInputWithDiv(this.accountNumberInputTarget)
    }
  }

  replaceInputWithDiv(input) {
    var div = document.createElement("div")
    div.setAttribute("id", input.id)
    div.setAttribute("class", "iframe-field")
    input.parentNode.replaceChild(div, input)
  }

  tokenizationCallback() {
    var controller = this;

    return function(state) {
      if(state.isReady) {
        controller.submitButtonTarget.removeAttribute('disabled');
      }
      else {
        controller.submitButtonTarget.setAttribute('disabled', 'disabled')
      }
    }
  }

  expMonth() {
    // console.log("month: " + /^[\s0]*(0?[1-9]|10|11|12)\s*[\/\-\s]?\s*(\d{2})?(\d{2})\s*$/ig.exec(this.expDateInputTarget.value)[1])
    return /^[\s0]*(0?[1-9]|10|11|12)\s*[\/\-\s]?\s*(\d{2})?(\d{2})\s*$/ig.exec(this.expDateInputTarget.value)[1]
  }

  expYear() {
    // console.log("year: " + /^[\s0]*(0?[1-9]|10|11|12)\s*[\/\-\s]?\s*(\d{2})?(\d{2})\s*$/ig.exec(this.expDateInputTarget.value)[3])
    return /^[\s0]*(0?[1-9]|10|11|12)\s*[\/\-\s]?\s*(\d{2})?(\d{2})\s*$/ig.exec(this.expDateInputTarget.value)[3]
  }

  paymentType() {
    try { this.ccTabTarget } catch (e) { return 'ach' }
    return this.ccTabTarget.classList.contains('tab-active') ? 'cc' : 'ach'
  }

  tokenizeAndSubmit(event) {
    event.preventDefault();
    var controller = this;

    this.tokenize((result) => {
      controller.formTarget.submit();
    })
  }

  tokenize(callback) {
    var controller = this;
    var tokenParams;
    var hostedFields;

    if(this.paymentType() == 'cc') {
      tokenParams = this.ccTokenParams();
      hostedFields = this.ccHostedFields;
    }
    else {
      tokenParams = this.achTokenParams();
      hostedFields = this.achHostedFields;
    }

    if(!hostedFields.getState()) {
      //send error
      return
    }

    hostedFields.getPaymentToken(tokenParams)
    .then(function(result) {
      controller.tokenInputTarget.value = result.id;
      controller.paymentTypeInputTarget.value = controller.paymentType();
      callback(result);
      // If getPaymentToken returns successfully you may pass your payment token to your backend service.
    }).catch(function(err) {
      console.log(err)
    })
  }

  ccTokenParams() {
    return {
      'exp_year': this.expYear(),
      'exp_month': this.expMonth(),
      'name': this.ccNameInputTarget.value,
      'address1': this.ccAddr1InputTarget.value,
      'address2': this.ccAddr2InputTarget.value,
      'city': this.ccLocalityInputTarget.value,
      'state': this.ccRegionInputTarget.value,
      'postal_code': this.ccPostalCodeInputTarget.value,
      'email': this.emailValue,
    }
  }

  achNameParams() {
    if(this.achAcctHolderTypeInputTarget.value == 'business') {
      return {
        'name': this.achNameInputTarget.value
      }
    } else {
      return {
        'given_name': this.achNameInputTarget.value.split(' ', 2)[0],
        'surname': this.achNameInputTarget.value.split(' ', 2)[1]
      }
    }
  }

  achTokenParams() {
    return Object.assign(
      {
       'account_holder_type': this.achAcctHolderTypeInputTarget.value,
       'account_type': this.achAcctTypeInputTarget.value,
       'email': this.emailValue,
      },
      this.achNameParams()
    )
  }

  hostedFieldsConfiguration(fieldsConfig) {
    var config = {
      publicKey: this.publicKeyValue,
      input: {
        css: {
          'font-family': 'pragmatica, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
          'line-height': '1.25',
          'border': '1px solid rgb(202, 208, 226)',
          'border-radius': '4px',
          'padding': '12px 16px',
          'height': 'auto',
          'width': '100%',
          'font-size': '16px',
          'color': 'hsla(235,13%,35%,1)', // fieldConfig css will overwrite this value
          'box-shadow': 'none',
          '@media screen (max-width: 768px)': {
            'font-size': '14px'
          },
          ':focus': {
            'border-color': 'hsla(230,12%,52%,1)',
            'color': 'hsla(235,13%,35%,1)',
            'outline': '0',
            'box-shadow': 'none',
          },
          ':invalid, :invalid:focus': {
            'background': '#fff3f3',
            'border-color': '#F37B7B',
            'color': '#d26969',
            'outline': '0'
          },
        }
      },
      fields: fieldsConfig
    }

    return config;
  }

  ccFieldsConfig() {
    return [
      {
        selector: "#" + this.cardNumberInputTarget.id,
        input: {
          type: "credit_card_number"
        }
      },
      {
        selector: "#" + this.cvvInputTarget.id,
        input: {
          type: "cvv"
        }
      },
    ]
  }

  achFieldsConfig() {
    return [
      {
        selector: "#" + this.routingNumberInputTarget.id,
        input: {
          type: "routing_number"
        }
      },
      {
        selector: "#" + this.accountNumberInputTarget.id,
        input: {
          type: "bank_account_number"
        }
      }
    ]
  }
}
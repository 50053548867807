import { Controller } from "stimulus"

export default class RequiredFormController extends Controller {
  static targets = ["field", "submit"]

  declare fieldTargets: Array<HTMLInputElement>
  declare submitTarget: HTMLInputElement

  connect() {
    this.submitTarget.disabled = true
  }

  checkForm() {
    var complete = true
    this.fieldTargets.forEach((fieldTarget) => {
      complete = complete && ((fieldTarget.value !== null) as boolean)
    })

    this.submitTarget.disabled = !complete
    return
  }
}

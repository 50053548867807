import { Controller } from 'stimulus';

export default class NavMenuController extends Controller {
  static targets = ["menu", "menuRoot", "toggleIcon"]
  static actions = ["toggle"]
  static values = { visible: Boolean }
  static classes = ["toggleIcon"]

  declare menuTarget: HTMLElement
  declare menuRootTarget: HTMLElement
  declare toggleIconTarget: HTMLElement
  declare toggleIconClass: string
  declare visibleValue: boolean

  toggle() {
    this.flipState()
  }

  adjustTarget() {
    this.menuRootTarget.setAttribute('aria-expanded', this.visibleValue.toString())
    this.menuTarget.setAttribute('aria-hidden', (!this.visibleValue).toString())
    this.toggleIconTarget.classList.toggle(this.toggleIconClass, this.visibleValue)
  }

  flipState() {
    this.visibleValue = !this.visibleValue
  }

  visibleValueChanged() {
    this.adjustTarget()
  }
}
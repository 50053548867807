import { Controller } from "stimulus"
import autosize from "autosize";

export default class NestedFieldsController extends Controller {
  // orderField is optional and just meant to be a convenience for orderable nested fields
  static targets = ["template", "inserter", "orderField"]

  declare templateTarget: HTMLElement
  declare inserterTargets: Array<HTMLElement>
  declare orderFieldTargets: Array<HTMLInputElement>

  addFields(event) {
    event.preventDefault()
    var inserter = (event.target as HTMLElement).closest('[data-nested-fields-target="inserter"]')
    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
    inserter.insertAdjacentHTML(inserter.getAttribute('data-nested-fields-insert') as InsertPosition, content)
    this.assignOrders()

    // Notify the DOM to run the form prep methods
    const prep_event = new CustomEvent('prep-forms')
    document.dispatchEvent(prep_event)
  }

  removeFields(event: Event) {
    event.preventDefault()
    let wrapper: HTMLElement = (event.target as HTMLElement).closest(".nested-field")

    // New records are simply removed from the page
    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()

    // Existing records are hidden and flagged for deletion
    } else {
      (wrapper.querySelector("input[name*='_destroy']") as HTMLInputElement).value = '1'
      wrapper.style.display = 'none'
    }
  }

  assignOrders() {
    this.orderFieldTargets.forEach((orderField, index) => {
      orderField.value = index.toString()
    })
  }
}

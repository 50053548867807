import { Controller } from "stimulus"

export default class SelectPanelController extends Controller {
  static targets = ["select", "panel"]
  static values = { "choice": String }

  declare panelTargets: Array<Element>
  declare selectTarget: HTMLInputElement
  declare choiceValue: string

  connect() {
    this.changePanel()
  }

  changePanel() {
    this.choiceValue = this.selectTarget.value
  }

  showPanel() {
    this.panelTargets.forEach((panel) => {
      var active = panel.getAttribute('data-select-panel-key') == this.choiceValue
      panel.classList.toggle("hidden", !active)

      if (active) {
        panel.setAttribute("aria-hidden", "false")
      } else {
        panel.setAttribute("aria-hidden", "true")
      }
    })

    this.prepForms();
  }

  choiceValueChanged() {
    this.showPanel()
  }

  prepForms() {
    setTimeout(()=> {
      // Notify the DOM to run the form prep methods
      const prep_event = new CustomEvent('prep-forms')
      document.dispatchEvent(prep_event)
    }, 0)
  }
}

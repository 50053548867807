import { Controller } from 'stimulus';

export default class AlertController extends Controller {
  static targets = ["alert", "toggle"]
  static actions = ["hide"]
  static classes = ["hide"]
  static values = { autohide: Boolean }

  declare alertTarget: HTMLElement
  declare toggleTarget: HTMLElement
  declare autohideValue: boolean
  declare hideClass: string

  connect() {
    this.alertTarget.classList.add('scale-0');
    this.alertTarget.classList.add('transform');
    this.alertTarget.classList.add('transition-transform');
    setTimeout(() => {
      this.show();
      if(this.autohideValue) {
        setTimeout(() => {
          this.hide();
        }, 5000)
      }
    }, 2000)
  }

  show() {
    this.alertTarget.classList.remove(this.hideClass)
    setTimeout(() => {
      this.alertTarget.classList.remove('scale-0');
    }, 10)
  }

  hide() {
    this.alertTarget.classList.add('scale-0');
    this.alertTarget.classList.remove('scale-100');
    setTimeout(() => {
      this.alertTarget.classList.add(this.hideClass)
    }, 1000)
  }
}
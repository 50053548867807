import { Controller } from "stimulus"
import { Application } from "stimulus"
import Rails from '@rails/ujs'

export default class UploadController extends Controller {
  static targets = ["fileInput", "form"]

  declare fileInputTarget: HTMLInputElement
  declare file: File
  declare reader: FileReader
  declare inputName: string
  declare formTarget: HTMLFormElement

  connect() {
    this.reader = new FileReader

    // Remove the name as a way of preventing submission of an empty
    // field whish would erase the image.
    this.inputName = this.fileInputTarget.getAttribute('name')
    this.fileInputTarget.removeAttribute('name')
  }

  chooseFile(e) {
    this.clearForm(e)
    this.fileInputTarget.click()
  }

  clearForm(e) {
    this.formTarget.reset()
  }

  uploadFile() {
    this.file = this.fileInputTarget.files[0]
    this.reader.readAsDataURL(this.file)
    this.reader.onload = this.onreadHandler()
  }

  onreadHandler() {
    const ctrl = this;

    return (e: ProgressEvent<FileReader>) => {
      // ctrl.previewTarget.src = <string> ctrl.reader.result
      // Add the name back in so that upon submission, the file
      // is uploaded
      ctrl.fileInputTarget.setAttribute('name', ctrl.inputName)
      Rails.fire(ctrl.formTarget, "submit")
    }
  }
}

import { Controller } from 'stimulus';

export default class LoadRemoteModalController extends Controller {
  static targets = [ "modal" ]

  declare modalTarget: HTMLElement

  connect() {
    document.getElementById('remote_modals').append(this.modalTarget)
  }
}
import autosize from "autosize";
import Choices from 'choices.js';
import _ from 'underscore';

function initForms() {

  const Forms = {
    init() {
      this.fileInputToggle();
      this.autosizeTextareas();
      this.bindSelects();
    },

    bindSelects() {
      [...[].slice.call(document.querySelectorAll('select'))].forEach((select) => {
        if(Boolean(select.closest('.flatpickr-calendar'))) { return }

        var choicesOpts = JSON.parse(select.getAttribute('data-choices-opts')) || {}

        select.choices = new Choices(select, _.extend({
          searchEnabled: false,
          shouldSort: false,
          silent: true,
          itemSelectText: "Press to select"
        }, choicesOpts))
      })
    },

    clearSelectPreCache(selectContainer) {
      // Remove the select filters since they'll interrupt things when loading from cache
      document.addEventListener("turbolinks:before-cache", function removeSelect(event) {
        selectContainer.innerHTML = null
        // Only do this once, so clean up the callback
        event.currentTarget.removeEventListener(event.type, removeSelect)
      })
    },

    autosizeTextareas() {
      autosize(document.querySelectorAll('textarea'));
      autosize.update(document.querySelectorAll('textarea'));
    },

    fileInputToggle() {
      const inputs = document.querySelectorAll('.form-file');

      inputs.forEach(function(input) {
        const label  = input.nextElementSibling,
        labelVal = label.innerHTML;

        input.addEventListener('change', function(e) {
          var fileName = '';

          if (this.files && this.files.length > 1) {
            fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
          } else {
            fileName = e.target.value.split( '\\' ).pop();
          }

          if (fileName) {
            label.querySelector( 'span' ).innerHTML = fileName;
          } else {
            label.innerHTML = labelVal;
          }
        });
      });
    }
  };

  Forms.init();
}

document.addEventListener("turbolinks:load", initForms);
document.addEventListener("prep-forms", initForms);
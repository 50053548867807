import { Controller } from "stimulus"
import * as _ from 'underscore';

export default class LifecycleChartController extends Controller {
  static targets = ["period"]

  declare periodTargets: Array<HTMLElement>
  declare duration: number

  connect() {
    var self = this
    self.duration = 0.0
    this.periodTargets.forEach((period) => {
      self.duration += Number(period.getAttribute('data-lifecycle-chart-duration'))
    })

    setTimeout(this.resizePeriods(), 750)
  }

  resizePeriods() {
    var self = this
    return () => {
      self.periodTargets.forEach((period) => {
        var duration = Number(period.getAttribute('data-lifecycle-chart-duration'));
        period.style.minWidth = period.clientWidth + "px"
        period.style.width = period.style.minWidth;
        var newWidth = (duration * 100.0 / self.duration) + "%"
        setTimeout(() => {
          period.style.width = newWidth
        }, 10)
      })
    }
  }
}

import { Controller } from "stimulus"

export default class ItemSelectorController extends Controller {
  static actions = ["setItem"]
  static targets = ["field", "item"]
  static values = { "choice": String }
  static classes = ["selected"]

  declare itemTargets: Array<HTMLElement>
  declare fieldTargets: Array<HTMLInputElement>
  declare choiceValue: string
  declare selectedClasses: Array<string>

  connect() {
    this.selectItem();
  }

  selectItem() {
    this.itemTargets.forEach((itemTarget) => {
      if(itemTarget.getAttribute('data-item-selector-item') == this.choiceValue) {
        this.selectedClasses.forEach((cl) => {
          itemTarget.classList.add(cl)
        })
      } else if (this.choiceValue !== '') {
        console.log(this.choiceValue)
        this.selectedClasses.forEach((cl) => {
          itemTarget.classList.remove(cl)
        })
      }
    })
  }

  setItem(e:Event) {
    this.choiceValue = (e.target as HTMLElement).closest('[data-item-selector-item]').getAttribute('data-item-selector-item')
  }

  choiceValueChanged() {
    this.fieldTargets.forEach((fieldTarget) => {
      fieldTarget.value = this.choiceValue
    })

    this.selectItem()
  }
}
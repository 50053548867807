import { Controller } from 'stimulus';
import autosize from "autosize";

export default class ModalController extends Controller {
  static targets = ["container"]
  static classes = ["toggle"]

  declare toggleClass: string
  declare containerTarget: HTMLElement
  declare background: HTMLElement
  declare scrollPosition: number
  declare lastFocused: HTMLElement
  declare focusableEls: Array<HTMLElement>
  declare form: HTMLFormElement
  declare autoclose: boolean

  connect() {
    this.containerTarget.classList.add(this.toggleClass);
    this.autoclose = this.containerTarget.getAttribute('data-modal-autoclose') == null || this.containerTarget.getAttribute('data-modal-autoclose') == 'true'
    var focusableEls = this.containerTarget.querySelectorAll('a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not([tabindex="-1"]), [tabindex="0"]');
    this.focusableEls = Array.prototype.slice.call(focusableEls);
    this.form = this.containerTarget.querySelector('form');

    if(this.form !== null && this.autoclose) {
      this.form.addEventListener('ajax:success', this.closeModalHandler())
    }
  }

  closeModalHandler() {
    const ctrl = this

    return (e) => {
      ctrl.close(e)
      ctrl.form.reset()
    }
  }

  focusFirstElement() {
    if(this.focusableEls.length > 0) {
      this.focusableEls[0].focus();
    }
  }

  autosizeTextareas() {
    setTimeout(() => {
      autosize.update(document.querySelectorAll('textarea'));
    }, 10)
  }

  open(e) {
    this.lastFocused = document.activeElement as HTMLElement;
    e.preventDefault();
    e.target.blur();

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass);
    this.containerTarget.setAttribute('aria-hidden', 'false')
    this.focusFirstElement();


    // Insert the background
    document.body.insertAdjacentHTML('beforeend', this.backgroundHtml());
    this.background = document.querySelector(`#modal-background`);

    this.autosizeTextareas();

    // Notify the DOM to run the form prep methods
    document.dispatchEvent(new CustomEvent('prep-forms'))
  };

  close(e: Event) {
    e.preventDefault();

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll();

    // Hide the modal
    this.containerTarget.classList.add(this.toggleClass);
    this.containerTarget.setAttribute('aria-hidden', 'true')

    // Re-focus on last focused item
    this.lastFocused.focus()

    // Remove the background
    if (this.background) { this.background.remove() }

    const event = new CustomEvent('modal-closed')
    document.dispatchEvent(event)
  }

  closeBackground(e: Event) {
    if (e.target === this.containerTarget) {
      this.close(e);
    }
  }

  closeWithKeyboard(e: KeyboardEvent) {
    if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
      this.close(e);
    }
  }

  backgroundHtml() {
    return '<div id="modal-background" class="fixed top-0 left-0 w-full h-full bg-gradient-radial from-gray-700 to-black opacity-75 transition-opacity" style="z-index: 9998;" tabindex="-1"></div>';
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition();

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }
}
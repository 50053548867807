import { Controller } from "stimulus"
import autosize from "autosize";
import { Application } from "stimulus"
import ModalController from 'controllers/modal_controller';

export default class MessageAttachmentController extends Controller {
  static targets = ["messageField", "fileInput", "previewFilename", "fileSize"]

  declare messageFieldTarget: HTMLTextAreaElement
  declare fileInputTarget: HTMLInputElement
  declare fileSizeTarget: HTMLElement
  declare previewFilenameTarget: HTMLElement
  declare file: File
  declare reader: FileReader
  declare inputName: string
  declare form: HTMLFormElement

  connect() {
    this.reader = new FileReader
    this.form = this.fileInputTarget.closest('form')

    // Remove the name as a way of preventing submission of an empty
    // field whish would erase the image.
    this.inputName = this.fileInputTarget.getAttribute('name')
    this.fileInputTarget.removeAttribute('name')
    this.form.addEventListener('ajax:complete', this.closeModalHandler())
  }

  autosizeTextareas() {
    setTimeout(() => {
      autosize.update(this.messageFieldTarget);
    }, 1)
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

  previewAndOpenModal(e) {
    this.preview();
    (this.modalController() as ModalController).open(e)
    this.modalAfterOpen(e)
  }

  modalBeforeOpen(e) {
    this.clearForm(e)
    this.fileInputTarget.click()
  }

  modalAfterOpen(e) {
    this.autosizeTextareas()
  }

  closeModalHandler() {
    const ctrl = this

    return (e) => {
      (ctrl.modalController() as ModalController).close(e)
      ctrl.clearForm(e)
    }
  }

  clearForm(e) {
    this.form.reset()
  }

  preview() {
    this.file = this.fileInputTarget.files[0]
    this.reader.readAsDataURL(this.file)
    this.reader.onload = this.onreadHandler()
    this.messageFieldTarget.value = (document.querySelector('#fixed_message_form textarea') as HTMLTextAreaElement).value
    this.previewFilenameTarget.innerHTML = this.sanitize(this.file.name)
    this.fileSizeTarget.innerText = this.formatBytes(this.file.size)
  }

  onreadHandler() {
    const ctrl = this;

    return (e: ProgressEvent<FileReader>) => {
      // ctrl.previewTarget.src = <string> ctrl.reader.result
      // Add the name back in so that upon submission, the file
      // is uploaded
      ctrl.fileInputTarget.setAttribute('name', ctrl.inputName)
    }
  }

  modalController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "modal")
  }

  sanitize(string) {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    const reg = /[&<>"'/]/ig;
    return string.replace(reg, (match)=>(map[match]));
  }
}

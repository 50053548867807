import { Controller } from 'stimulus';

export default class ClipboardController extends Controller {
  static actions = ["copy"]
  static values = { copy: String, copied: String }
  static targets = ["trigger"]

  declare copyValue: string
  declare triggerContent: string
  declare copiedValue: string
  declare source: HTMLTextAreaElement
  declare triggerTarget: HTMLButtonElement


  connect() {
    this.triggerContent = this.triggerTarget.innerHTML
    this.element.insertAdjacentHTML('afterend', '<textarea readonly aria-hidden="true" class="offscreen">' + this.copyValue + '</textarea>')
    this.source = this.element.nextElementSibling as HTMLTextAreaElement;
  }

  copy() {
    event.preventDefault();
    this.source.select();
    document.execCommand('copy');

    this.triggerTarget.innerHTML = this.copiedValue
    setTimeout(()=> {
      this.triggerTarget.innerHTML = this.triggerContent
    }, 3000)
  }
}
import { Controller } from "stimulus"

export default class TempFieldController extends Controller {
  static targets = ["field", "localPanel"]

  declare fieldTarget: HTMLInputElement
  declare choices: any
  declare initialValue: any
  declare localPanelTargets: Array<HTMLElement>

  connect() {
    this.initialValue = this.fieldTarget.value;

    this.reset();
  }

  reset() {
    var ch = this.fieldTarget.closest('.choices')
    if(ch == null) {
      this.fieldTarget.value = this.initialValue
      this.changeLocalPanels()
    }
    else {
      var ctrlr = this;

      setTimeout(()=> {
        (ctrlr.fieldTarget as any).choices.setChoiceByValue(ctrlr.initialValue)
        ctrlr.changeLocalPanels()
      }, 0)
    }
  }

  changeLocalPanels() {
    this.localPanelTargets.forEach((panel) => {
      var active = panel.getAttribute('data-temp-field-panel-key') == this.fieldTarget.value
      panel.classList.toggle("hidden", !active)

      if (active) {
        panel.setAttribute("aria-hidden", "false")
      } else {
        panel.setAttribute("aria-hidden", "true")
      }
    })
  }

  apply() {
    this.initialValue = this.fieldTarget.value
    const event = new CustomEvent("temp-field-applied")
    this.fieldTarget.dispatchEvent(event)
  }
}

import { Controller } from 'stimulus';

export default class AnimateController extends Controller {
  static targets = ['item']
  static classes = ['animation']
  static values = { delay: Number, duration: Number }

  declare itemTarget: HTMLElement
  declare animationClasses: Array<string>
  declare delayValue: number
  declare durationValue: number

  connect() {
    setTimeout( ()=> {
      this.animationClasses.forEach((cl) => {
        this.itemTarget.classList.add(cl)
      })
      setTimeout(() => {
        this.animationClasses.forEach((cl) => {
          this.itemTarget.classList.remove(cl)
        })
      }, this.durationValue)
    }, this.delayValue)
  }
}
import { Controller } from 'stimulus';
import { Application } from 'stimulus';

export default class GeocompleteController extends Controller {
  static targets = ['gc', 'componentField', 'streetNumber','route','locality','region','country','postalCode','timeZone','lat','lng']
  static actions = []
  static classes = []
  static values = { 'googleApiKey': String }

  declare gcTarget: HTMLInputElement
  declare componentFieldTargets: Array<HTMLInputElement>
  declare streetNumberTarget: HTMLInputElement
  declare routeTarget: HTMLInputElement
  declare localityTarget: HTMLInputElement
  declare regionTarget: HTMLInputElement
  declare countryTarget: HTMLInputElement
  declare postalCodeTarget: HTMLInputElement
  declare timeZoneTarget: HTMLInputElement
  declare latTarget: HTMLInputElement
  declare lngTarget: HTMLInputElement
  declare autocomplete: google.maps.places.Autocomplete
  declare googleApiKeyValue: string

  connect() {
    this.autocomplete = new google.maps.places.Autocomplete(this.gcTarget, { types: ['geocode'] })
    const ctrlr = this

    var observerHack = new MutationObserver(function() {
        observerHack.disconnect();
        ctrlr.gcTarget.autocomplete = 'new-password'
    });

    observerHack.observe(this.gcTarget, {
      attributes: true,
      attributeFilter: ['autocomplete']
    })

    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(['address_component', 'formatted_address', 'geometry']);

    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.addListener('place_changed', this.fillInAddress());

    this.gcTarget.addEventListener('keydown', this.blurAutocomplete())
    this.gcTarget.addEventListener('change', (event) => {
      if(ctrlr.gcTarget.value == '') {
        ctrlr.element.querySelectorAll('input[data-geocomplete-target]').forEach((target) => {
          (target as HTMLInputElement).value = ''
        })
      }
    })
  }

  blurAutocomplete() {
    const ctrlr = this;

    return function(event) {
      if (event.keyCode == 13) {
        event.preventDefault();
        ctrlr.gcTarget.blur()
      }
    }
  }

  fillInAddress() {
    const ctrlr = this;

    return function() {

      // Get the place details from the autocomplete object.
      var place = ctrlr.autocomplete.getPlace()

      ctrlr.componentFieldTargets.forEach((comp) => {
        comp.value = ''
        comp.disabled = false
      })

      ctrlr.gcTarget.value = place.formatted_address
      ctrlr.latTarget.value = place.geometry.location.lat().toString()
      ctrlr.lngTarget.value = place.geometry.location.lng().toString()

      place.address_components.forEach((component, index) => {
        switch(component.types[0]) {
          case 'street_number':
            ctrlr.streetNumberTarget.value = component.long_name
            break;
          case 'route':
            ctrlr.routeTarget.value = component.long_name
            break;
          case 'locality':
            ctrlr.localityTarget.value = component.long_name
            break
          case 'administrative_area_level_1':
            ctrlr.regionTarget.value = component.short_name
            break
          case 'country':
            ctrlr.countryTarget.value = component.short_name
            break
          case 'postal_code':
            ctrlr.postalCodeTarget.value = component.short_name
            break
        }
      })

      ctrlr.getTimeZone()
    }
  }


  getTimeZone() {
    var xhr = new XMLHttpRequest();
    var latLngStr = this.latTarget.value + ',' + this.lngTarget.value;
    var ctrlr = this

    // Setup our listener to process completed requests
    xhr.onload = function () {

      // Process our return data
      if (xhr.status >= 200 && xhr.status < 300) {
        // This will run when the request is successful
        ctrlr.timeZoneTarget.value = JSON.parse(xhr.response).timeZoneId
      } else {
        // This will run when it's not
        console.debug('The request failed!');
      }
    };

    // Create and send a GET request
    // The first argument is the post type (GET, POST, PUT, DELETE, etc.)
    // The second argument is the endpoint URL
    xhr.open('GET', 'https://maps.googleapis.com/maps/api/timezone/json?location=' + latLngStr + '&timestamp='+(Math.round((new Date().getTime())/1000)).toString()+'&sensor=false&key=' + this.googleApiKeyValue);
    xhr.send();
  }
}
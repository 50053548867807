import { Controller } from 'stimulus';
import { Application } from "stimulus"
import * as CronofyElements from 'cronofy-elements';
import spacetime from 'spacetime';
import LawpayPaymentFormController from 'controllers/lawpay_payment_form_controller'

export default class SchedulerBookingController extends Controller {
  static targets = ["elementDestination", "user", "timeInput", "stepPanel", "whenSummary", "whoSummary", "paymentSummary", "contactName", "contactEmail", "ccIcon", "achIcon", "tzLabel", "form", "nextButton", "paymentButton"]
  static values = { clientId: String, elementToken: String, meetingSub: String, meetingTypeId: String, meetingDuration: Number, bufferBefore: Number, bufferAfter: Number, start: String, end: String, eventAt: String, timezone: String, paymentController: String, tzMappings: Object }
  static classes = ["panelHide"]

  declare nextButtonTargets: Array<HTMLButtonElement>
  declare paymentButtonTargets: Array<HTMLButtonElement>
  declare formTarget: HTMLFormElement
  declare elementDestinationTarget: HTMLElement
  declare userTargets: Array<HTMLElement>
  declare stepPanelTargets: Array<HTMLElement>
  declare whenSummaryTarget: HTMLElement
  declare whoSummaryTarget: HTMLElement
  declare paymentSummaryTarget: HTMLElement
  declare contactNameTarget: HTMLInputElement
  declare contactEmailTarget: HTMLInputElement
  declare ccIconTarget: HTMLElement
  declare achIconTarget: HTMLElement
  declare tzLabelTargets: Array<HTMLElement>
  declare tzMappingsValue: Object
  declare redirectUriValue: string
  declare clientIdValue: string
  declare elementTokenValue: string
  declare meetingSubValue: string
  declare meetingTypeIdValue: string
  declare meetingDurationValue: number
  declare bufferBeforeValue: number
  declare bufferAfterValue: number
  declare startValue: string
  declare endValue: string
  declare panelHideClass: string
  declare currentPanelIndex: number
  declare eventAtValue: string
  declare timezoneValue: string
  declare paymentControllerValue: string
  declare timeInputTarget: HTMLInputElement
  declare tzUser: string

  connect() {
    this.tzUser = this.localTimezone()
    this.tzLabelTargets.forEach((t) => {
      t.innerHTML = this.tzMappingsValue[this.tzUser]
    })
    this.currentPanelIndex = 0
    CronofyElements.SlotPicker({
      target_id: this.elementDestinationTarget.id,
      tzid: this.tzUser,
      availability_query: {
        participants: [
          {
            members: this.userCronofySubs(),
            required: 'all',
          }
        ],
        required_duration: { minutes: this.meetingDurationValue },
        buffer: {
          before: { minutes: this.bufferBeforeValue },
          after: { minutes: this.bufferAfterValue },
        },
        available_periods: [
          {
            start: this.startValue,
            end: this.endValue,
          }
        ]
      },
      element_token: this.elementTokenValue,
      styles: {
        prefix: 'scheduler'
      },
      config: {
        mode: 'no_confirm'
      },
      callback: this.callbackHandler()
      // demo: true,
    })

    this.updateName()

    const ctrlr = this
    this.formTarget.addEventListener('keydown', (e) => {
      if(e.keyCode == 13 && !ctrlr.onLastPanel()) {
        e.preventDefault();
        return false;
      }
    }, true);

    this.nextButtonTargets.forEach((btn) => {
      btn.addEventListener('keydown', (e) => {
        if(e.keyCode == 13) {
          ctrlr.advance()
        }
      })
    })

    this.paymentButtonTargets.forEach((btn) => {
      btn.addEventListener('keydown', (e) => {
        if(e.keyCode == 13) {
          ctrlr.tokenizePayment()
        }
      })
    })
  }

  onLastPanel() {
    return this.currentPanelIndex == (this.stepPanelTargets.length - 1)
  }

  updateName() {
    this.setSummaryValue(this.whoSummaryTarget, this.contactNameTarget.value)
    if(this.contactNameTarget.value != "") {
      this.whoSummaryTarget.classList.remove(this.panelHideClass)
    }
  }

  activePanel() {
    return this.stepPanelTargets[this.currentPanelIndex]
  }

  advance() {
    this.stepPanelTargets.forEach((panel) => {
      panel.classList.add(this.panelHideClass)
    })
    this.currentPanelIndex++;
    this.stepPanelTargets[this.currentPanelIndex].classList.remove(this.panelHideClass)
    // Notify the DOM to run the form prep methods
    const prep_event = new CustomEvent('prep-forms')
    document.dispatchEvent(prep_event)

    var focusableEls = this.activePanel().querySelectorAll('a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not([tabindex="-1"]), [tabindex="0"]');
    Array.prototype.slice.call(focusableEls)[0].focus();
  }

  back() {
    this.stepPanelTargets.forEach((panel) => {
      panel.classList.add(this.panelHideClass)
    })
    this.currentPanelIndex--;
    this.stepPanelTargets[this.currentPanelIndex].classList.remove(this.panelHideClass)
    // Notify the DOM to run the form prep methods
    const prep_event = new CustomEvent('prep-forms')
    document.dispatchEvent(prep_event)
  }

  callbackHandler() {
    var ctrlr = this

    return (notification) => {
      if(notification.notification.type == 'slot_selected') {
        ctrlr.eventAtValue = notification.notification.slot.start
        ctrlr.advance();
      }
    }
  }

  userCronofySubs() {
    var subs = [{ sub: this.meetingSubValue, availability_rule_ids: [this.meetingTypeIdValue], managed_availability: true }]
    this.userTargets.forEach((userTarget) => {
      subs.push(
        {
          sub: userTarget.getAttribute('data-scheduler-booking-sub'),
          availability_rule_ids: [userTarget.getAttribute('data-scheduler-user-id')],
          managed_availability: true,
        })
    })

    return subs
  }

  eventAtValueChanged() {
    this.timeInputTarget.value = this.eventAtValue
    var st = spacetime(this.eventAtValue).goto(this.localTimezone())
    this.setSummaryValue(this.whenSummaryTarget, st.format('{day}, {month} {date-ordinal} at {time} {ddd}') as string);

    if(this.timeInputTarget.value != "") {
      this.whenSummaryTarget.classList.remove(this.panelHideClass)
    }
  }

  tokenizePayment() {
    var ctrlr = this;

    (this.paymentsController() as LawpayPaymentFormController).tokenize((result) => {
      ctrlr.setPaymentIcon(result.type)
      ctrlr.setSummaryValue(ctrlr.paymentSummaryTarget, result.number || result.routing_number)
      ctrlr.paymentSummaryTarget.classList.remove(ctrlr.panelHideClass)
      ctrlr.advance()
    })
  }


  setPaymentIcon(type:string) {
    if(type == 'card') {
      this.ccIconTarget.classList.remove(this.panelHideClass)
      this.achIconTarget.classList.add(this.panelHideClass)
    } else {
      this.ccIconTarget.classList.add(this.panelHideClass)
      this.achIconTarget.classList.remove(this.panelHideClass)
    }
  }

  localTimezone() {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if(urlParams.has('tzfreeze')) {
      return this.timezoneValue
    } else {
      return Intl.DateTimeFormat().resolvedOptions().timeZone
    }
  }

  setSummaryValue(summaryTarget:HTMLElement, body:string) {
    summaryTarget.querySelector('.scheduler-booking-summary-value').innerHTML = body
  }

  paymentsController() {
    return this.application.getControllerForElementAndIdentifier(this.element, this.paymentControllerValue)
  }
}
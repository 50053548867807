import { Controller } from 'stimulus';
import { Application } from "stimulus"

export default class FormBuilderController extends Controller {
  // static targets = ["tempField"]
  // static actions = ["toggle", "hide"]
  // static classes = ["toggle"]

  // tempFieldTargets: Array<HTMLElement>
  // application: Application

  // resetTempFields(e) {
  //   this.tempFieldTargets.forEach((tft) => {
  //     this.application.getControllerForElementAndIdentifier(tft, 'temp-field').reset()
  //   })
  // }

  // applyFieldChanges() {
  //   this.tempFieldTargets.forEach((tft) => {
  //     this.application.getControllerForElementAndIdentifier(tft, 'temp-field').apply()
  //   })
  // }
}
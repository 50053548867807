import { Controller } from 'stimulus';

export default class RemoteModalTriggerController extends Controller {
  static values = { remoteModal: String }

  declare remoteModalValue: string

  connect() {
    if(this.element.hasAttribute('data-remote-modal-trigger-auto-open')) {
      document.addEventListener("turbolinks:load", (e) => {
        this.open(null);
      })
    }
  }

  open(e) {
    if(e !== null && this.confirming(e)) { return }

    if (e !== null) { e.preventDefault() }
    const remoteModal = document.querySelector('[data-remote-modal=' + this.remoteModalValue + ']')
    const trigger = remoteModal.querySelector('#remote_trigger') as HTMLElement
    trigger.click()
  };

  confirming(event:Event):Boolean {
    const rootConfirm = (event.target as HTMLElement).closest('[data-confirm]')

    if( rootConfirm === null) { return false }

    const propagatedConfirm = (event.currentTarget as HTMLElement).closest('[data-confirm]')

    // if both nodes have a confirming parent then this node hasn't hit a confirmation yet
    if(rootConfirm === propagatedConfirm) { return false }

    return true
  }
}
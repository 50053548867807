import { Controller } from "stimulus"

export default class PlanSelectorController extends Controller {
  static targets = ["planField", "plan"]
  static values = { "choice": String }

  declare planTargets: Array<HTMLElement>
  declare planFieldTargets: Array<HTMLInputElement>
  declare choiceValue: string

  selectPlan() {
    this.planTargets.forEach((planTarget) => {
      if(planTarget.getAttribute('data-plan-selector-plan') == this.choiceValue) {
        planTarget.classList.remove('plan-selector--unselected')
        planTarget.classList.add('plan-selector--selected');
        setTimeout(()=> {
          (planTarget.querySelector('input.__PrivateStripeElement-input') as HTMLInputElement).focus();
        }, 100)
      } else if (this.choiceValue !== '') {
        console.log(this.choiceValue)
        planTarget.classList.remove('plan-selector--selected')
        planTarget.classList.add('plan-selector--unselected')
      }
    })
  }

  setPlan(e:Event) {
    this.choiceValue = (e.target as HTMLElement).closest('[data-plan-selector-plan]').getAttribute('data-plan-selector-plan')
  }

  choiceValueChanged() {
    this.planFieldTargets.forEach((planfieldTarget) => {
      planfieldTarget.value = this.choiceValue
    })

    this.selectPlan()
  }
}
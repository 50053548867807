
function resizePaperEditors() {
  var editors = document.querySelectorAll('trix-editor.paper') as NodeListOf<HTMLElement>;
  editors.forEach(function(editor: HTMLElement) {
    var w = editor.getBoundingClientRect().width;
    var h = w*11/8.5;
    editor.style.minHeight = h.toString() + "px";
  })
}

document.addEventListener("turbolinks:load", function () {
  resizePaperEditors();
})

import { Controller } from "stimulus"

export default class UrlInputController extends Controller {
  static targets = ["url"]
  static actions = ["addProtocol"]

  declare urlTarget: HTMLInputElement

  // If the input text doesn't have http in it, prepend it as the protocol
  addProtocol() {
    if (this.urlTarget.value && !~this.urlTarget.value.indexOf("http")) {
      this.urlTarget.value = 'http://' + this.urlTarget.value;
    }
  }
}

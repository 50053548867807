import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['form']

  connect() {
  }


  search() {
    this.formTarget.dispatchEvent(new CustomEvent('submit', {bubbles: true}))
  }
}

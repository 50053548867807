import { Controller, Application } from 'stimulus';
import TempFieldController from 'controllers/temp_field_controller'


export default class FormBuilderFieldController extends Controller {
  static targets = ["tempField", "label", "labelField"]
  static actions = ["toggle", "hide"]
  static classes = ["toggle"]

  declare tempFieldTargets: Array<HTMLElement>
  declare labelTargets: Array<HTMLElement>
  declare labelFieldTarget: HTMLInputElement

  resetTempFields(e) {
    this.tempFieldTargets.forEach((tft) => {
      (this.application.getControllerForElementAndIdentifier(tft, 'temp-field') as TempFieldController).reset()
    })
  }

  applyFieldChanges() {
    this.tempFieldTargets.forEach((tft) => {
      (this.application.getControllerForElementAndIdentifier(tft, 'temp-field') as TempFieldController).apply()
    })

    this.labelTargets.forEach((labelTarget)=> {
      labelTarget.innerHTML = this.labelFieldTarget.value
    })
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggleable" ]
  static classes = ["toggle"]

  declare toggleableTargets: Array<HTMLElement>
  declare toggleClass: string

  connect() {
  }

  toggle(event) {
    event.preventDefault()

    this.toggleableTargets.forEach( target => {
      var localToggleClasses = target.getAttribute('data-toggle-toggle-class') || 'hidden'

      localToggleClasses.split(' ').forEach((cl) => {
        target.classList.toggle(cl || 'hidden')
      })
    })
  }
}

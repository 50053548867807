import { Controller } from "stimulus"
import Tribute from "tributejs"
import Trix from "trix"

export default class MentionsController extends Controller {
  static values = { "mentionset": String }
  static targets = [ "field", "trixEditor" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  initializeTribute() {
    this.tribute = new Tribute({
      allowSpaces: true,
      trigger: '#',
      selectTemplate: function (item) {
        var mentionClass = (item.original.value == '' ? 'mention mention--blank' : 'mention')
        if (item.original.contentType == "csvariable--block") {
          mentionClass += " mention--block"
        }

        return '<span contenteditable="false" class="' + mentionClass + '" data-var-key="' + item.original.varKey + '"><span class="mention__value">' + item.original.value + '</span><span class="mention__label">' + item.original.key + '</span></span>'
      },
      requireLeadingSpace: false,
      replaceTextSuffix: '',
      values: JSON.parse(this.mentionsetValue),
    })
    this.tribute.attach(this.fieldTarget)
    this.tribute.range.pasteHtml = this._pasteHtml.bind(this)

    this.tribute.events.getKeyCode = function(instance, el, event) {
      if (event.isComposing) return

      let tribute = instance.tribute
      let info = tribute.range.getTriggerInfo(false, false, true, tribute.allowSpaces)

      if (info) {
        return info.mentionTriggerChar.charCodeAt(0)
      } else {
        return false
      }
    }

    // Prevent insertion of linebreak when closing mention dialog w/ return press
    var originalReturn = this.trixEditorTarget.editor.composition.delegate.inputController.keys.return
    var trib = this.tribute
    this.trixEditorTarget.editor.composition.delegate.inputController.keys.return = function(event) {
      if(trib.isActive) return
      originalReturn.call(this, event);
    }
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  _pasteHtml(html, startPos, endPos) {
    for(var i = endPos; i > startPos; i--) {
      this.trixEditorTarget.editor.deleteInDirection("backward") // Delete the trigger chracter
    }
    let contentType = this.contentTypeFromHtml(html)

    let attachment = new Trix.Attachment({ content: html, contentType: contentType })

    this.trixEditorTarget.editor.insertAttachment(attachment)

    if (contentType != "csvariable--block") {
      this.trixEditorTarget.editor.insertString(" ")
    }
  }

  contentTypeFromHtml(html) {
    return (html.includes('mention--block') ? 'csvariable--block' : 'csvariable')
  }
}

import { Controller } from "stimulus"

export default class AddClientsController extends Controller {
  static targets = ["addPersonButton", "addCompanyButton"]

  declare addPersonButtonTarget: HTMLElement
  declare addCompanyButtonTarget: HTMLElement

  addCompany(event: Event) {
    this.addCompanyButtonTarget.classList.add('hidden')
    this.addPersonButtonTarget.classList.add('hidden')
  }

  addPerson(event: Event) {
    this.addCompanyButtonTarget.classList.add('hidden')
  }
}
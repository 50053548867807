import { Controller } from 'stimulus'

export default class TimezoneGuesserController extends Controller {
  static targets = ['tzField']
  static values = { tzMappings: Object }

  declare tzFieldTarget: HTMLInputElement
  declare tzMappingsValue: Object

  connect() {
    var ctrlr = this;

    setTimeout(()=> {
      (ctrlr.tzFieldTarget as any).choices.setChoiceByValue(ctrlr.tzMappingsValue[Intl.DateTimeFormat().resolvedOptions().timeZone])
    }, 0)
  }
}
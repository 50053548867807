/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("trix")
require("@rails/actiontext")
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false

import LocalTime from "local-time"
import autosize from "autosize"
import Trix from "trix"
LocalTime.start()

import "stylesheets/application"

import "controllers"

import "src/confirm"
import "src/direct_uploads"
import "src/forms"
import "src/load_maps"
import "src/paper"
import "src/polyfills"
import "src/timezone"

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

document.addEventListener("turbolinks:load", () => {
  tippy(document.querySelectorAll('[data-tippy-content]'))
  setTimeout(() => {
    autosize.update(document.querySelectorAll('textarea'));
  },0);
})

document.addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target as Trix.Editor
  const inputElement = toolbarElement.querySelector("input[name=href]")
  inputElement.type = "text"
  inputElement.pattern = "(https?://|/).+"
})

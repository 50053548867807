import { Controller } from 'stimulus';

export default class DropdownController extends Controller {
  static targets = ["menu"]
  static actions = ["toggle", "hide"]
  static classes = ["toggle"]

  declare menuTarget: HTMLElement;
  declare toggleClasses: Array<string>;

  toggle() {
    this.toggleClasses.forEach((cl) => {
      this.menuTarget.classList.toggle(cl || 'hidden')
    })
  }

  hide(event: Event) {
    if (
      this.element.contains(event.target as Node) === false &&
      this.hasToggleClasses()
    ) {
      this.toggleClasses.forEach((cl) => {
        this.menuTarget.classList.remove(cl)
      })
    }
  }

  hasToggleClasses() {
    var hasClasses = true
    var menu = this.menuTarget
    this.toggleClasses.forEach((cl) => {
      hasClasses = hasClasses && menu.classList.contains(cl)
    })

    return hasClasses
  }
}